import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Sidebar.css';
import ProviderManual from '../assets/pdfs/Provider_Portal_User_Manual.pdf';

const Sidebar = () => {
  const { userType } = useAuth();

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>Provider Portal Menu</h2>
      </div>
      <ul className="sidebar-menu">
        <li>
          <Link to="/check-eligibility" className="menu-item-link">
            Check Eligibility
          </Link>
        </li>
        <li>
          <Link to="/add-utilization" className="menu-item-link">
            Add Utilization
          </Link>
        </li>
        {userType === 'Provider User' && (
          <li>
            <Link to="/provider-utilization-list" className="menu-item-link">
              Utilization List
            </Link>
          </li>
        )}
        {userType === 'Provider User' && (
          <li>
            <Link to="/provider-users" className="menu-item-link">
              View Users
            </Link>
          </li>
        )}
        <li>
          <a
            href={ProviderManual}
            target="_blank"
            rel="noopener noreferrer"
            className="menu-item-link"
          >
            Provider User Manual V1.1
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
