import axios from 'axios';

const baseURL =
  (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
    ? 'http://localhost:8000/'
    : 'https://portal.coremedhealth.com/';

const instance = axios.create({
  baseURL,
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  const csrftoken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
  if (csrftoken) {
    config.headers['X-CSRFToken'] = csrftoken;
  }
  return config;
});

export default instance;
